import React from 'react';
import _ from 'lodash';

import { withPrefix } from '../utils';

export default function SectionBackground(props) {
    
    const section = _.get(props, 'section');
    const backgroundImage = _.get(section, 'background_image');
    const backgroundImageOpacity = _.get(section, 'background_image_opacity', 100) * 0.01;
    const backgroundImageSize = _.get(section, 'background_image_size', 'cover');
    const backgroundImagePosition = _.get(section, 'background_image_position', 'center center');
    const backgroundImageRepeat = _.get(section, 'background_image_repeat', 'no-repeat');

    return (
        <div
            className="cover-img"
            style={{
                backgroundImage: `url('${withPrefix(backgroundImage)}')`,
                opacity: backgroundImageOpacity,
                backgroundSize: backgroundImageSize,
                backgroundPosition: backgroundImagePosition,
                backgroundRepeat: backgroundImageRepeat
            }}
        />
    );
}
